/* Copyright 2018 Mozilla Foundation
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in all
 * copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
 * SOFTWARE.
 */
import { File, Project, Directory, FileType, Problem, isBinaryFileType, fileTypeFromFileName, IStatusProvider } from "./models";
import { padLeft, padRight, isBranch, toAddress, decodeRestrictedBase64ToBytes, base64EncodeBytes } from "./util";
import { assert } from "./util";
import { gaEvent } from "./utils/ga";
import { WorkerCommand, IWorkerResponse } from "./message";
import { processJSFile, RewriteSourcesContext } from "./utils/rewriteSources";
import { getCurrentRunnerInfo } from "./utils/taskRunner";
import { createCompilerService, Language } from "./compilerServices";
import getConfig from "./config";
import * as yaml from "js-yaml"
import * as Base64 from 'base64-js'

declare var capstone: {
  ARCH_X86: any;
  MODE_64: any;
  Cs: any;
};

declare var Module: ({ }) => any;

declare var showdown: {
  Converter: any;
  setFlavor: Function;
};

export interface IFiddleFile {
  name: string;
  data?: string;
  type?: "binary" | "text";
}

export interface ICreateFiddleRequest {
  files: IFiddleFile[];
}

export interface ILoadFiddleResponse {
  files: IFiddleFile[];
  id: string;
  message: string;
  success: boolean;
}

export interface IGithubAccessToken {
  access_token: string,
  scope: string,
  token_type: string,
}

export interface ILoadGithubSourcesItem {
  git_url: string;
  name: string;
  path: string;
  type: 'file' | 'dir' | 'submodule';
}

export interface ILoadGithubFileContent {
  content: string;
  encoding: string;
}

export interface ILoadGithubTreeItem {
  path: string;
  url: string;
  type: 'blob' | 'tree';
}

export interface ILoadGithubGistFile {
  filename: string;
  content: string;
  truncated: boolean;
}

export interface ILoadGithubGistContent {
  truncated: boolean;
  files: {[key:string]: ILoadGithubGistFile}
}

export interface IGithubGistCreated {
  id: string
  html_url: string
}

export interface ILoadGithubTree {
  tree: ILoadGithubTreeItem[]
}

type ILoadGithubBlob = ILoadGithubFileContent

export interface IGithubFile {
  data: string;
  name: string;
  path: string;
}

interface IPlaySeedConfig {
  directories?: string[]
}

export interface IGithubLoadFail {
  success: false,
  error: Error;
}

export interface IGithubSourcesLoadSuccess {
  files: Array<IGithubFile>
  success: true
}

export type ILoadGithubSources = IGithubSourcesLoadSuccess | IGithubLoadFail


export { Language } from "./compilerServices";

function getProjectFilePath(file: File): string {
  const project = file.getProject();
  return file.getPath(project);
}

export class ServiceWorker {
  worker: Worker;
  workerCallbacks: Array<{ fn: (data: any) => void, ex: (err: Error) => void }> = [];
  nextId = 0;
  private getNextId() {
    return String(this.nextId++);
  }
  constructor() {
    this.worker = new Worker("dist/worker.bundle.js");
    this.worker.addEventListener("message", (e: { data: IWorkerResponse }) => {
      if (!e.data.id) {
        return;
      }
      const cb = this.workerCallbacks[e.data.id];
      if (e.data.success) {
        cb.fn(e.data.payload);
      } else {
        const error = Object.assign(
          Object.create(Error.prototype),
          e.data.payload,
        );
        cb.ex(error);
      }
      this.workerCallbacks[e.data.id] = null;
    });
  }

  setWorkerCallback(id: string, fn: (e: any) => void, ex?: (e: any) => void) {
    assert(!this.workerCallbacks[id as any]);
    this.workerCallbacks[id as any] = { fn, ex };
  }

  async postMessage(command: WorkerCommand, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const id = this.getNextId();
      this.setWorkerCallback(id, (data: any) => {
        resolve(data);
      }, (err: Error) => {
        reject(err);
      });
      this.worker.postMessage({
        id, command, payload
      }, undefined);
    });
  }

  async optimizeWasmWithBinaryen(data: ArrayBuffer): Promise<ArrayBuffer> {
    return await this.postMessage(WorkerCommand.OptimizeWasmWithBinaryen, data);
  }

  async validateWasmWithBinaryen(data: ArrayBuffer): Promise<number> {
    return await this.postMessage(WorkerCommand.ValidateWasmWithBinaryen, data);
  }

  async createWasmCallGraphWithBinaryen(data: ArrayBuffer): Promise<string> {
    return await this.postMessage(WorkerCommand.CreateWasmCallGraphWithBinaryen, data);
  }

  async convertWasmToAsmWithBinaryen(data: ArrayBuffer): Promise<string> {
    return await this.postMessage(WorkerCommand.ConvertWasmToAsmWithBinaryen, data);
  }

  async disassembleWasmWithBinaryen(data: ArrayBuffer): Promise<string> {
    return await this.postMessage(WorkerCommand.DisassembleWasmWithBinaryen, data);
  }

  async assembleWatWithBinaryen(data: string): Promise<ArrayBuffer> {
    return await this.postMessage(WorkerCommand.AssembleWatWithBinaryen, data);
  }

  async disassembleWasmWithWabt(data: ArrayBuffer): Promise<string> {
    return await this.postMessage(WorkerCommand.DisassembleWasmWithWabt, data);
  }

  async assembleWatWithWabt(data: string): Promise<ArrayBuffer> {
    return await this.postMessage(WorkerCommand.AssembleWatWithWabt, data);
  }

  async twiggyWasm(data: ArrayBuffer): Promise<string> {
    return await this.postMessage(WorkerCommand.TwiggyWasm, data);
  }
}

export class Service {
  private static worker = new ServiceWorker();

  static getMarkers(response: string): monaco.editor.IMarkerData[] {
    // Parse and annotate errors if compilation fails.
    const annotations: monaco.editor.IMarkerData[] = [];
    if (response.indexOf("(module") !== 0) {
      const re1 = /^.*?:(\d+?):(\d+?):\s(.*)$/gm;
      let m: any;
      // Single position.
      while ((m = re1.exec(response)) !== null) {
        if (m.index === re1.lastIndex) {
          re1.lastIndex++;
        }
        const startLineNumber = parseInt(m[1], 10);
        const startColumn = parseInt(m[2], 10);
        const message = m[3];
        let severity = monaco.MarkerSeverity.Info;
        if (message.indexOf("error") >= 0) {
          severity = monaco.MarkerSeverity.Error;
        } else if (message.indexOf("warning") >= 0) {
          severity = monaco.MarkerSeverity.Warning;
        }
        annotations.push({
          severity, message,
          startLineNumber: startLineNumber,
          startColumn: startColumn,
          endLineNumber: startLineNumber, endColumn: startColumn
        });
      }
      // Range. This is generated via the -diagnostics-print-source-range-info
      // clang flag.
      const re2 = /^.*?:\d+?:\d+?:\{(\d+?):(\d+?)-(\d+?):(\d+?)\}:\s(.*)$/gm;
      while ((m = re2.exec(response)) !== null) {
        if (m.index === re2.lastIndex) {
          re2.lastIndex++;
        }
        const message = m[5];
        let severity = monaco.MarkerSeverity.Info;
        if (message.indexOf("error") >= 0) {
          severity = monaco.MarkerSeverity.Error;
        } else if (message.indexOf("warning") >= 0) {
          severity = monaco.MarkerSeverity.Warning;
        }
        annotations.push({
          severity, message,
          startLineNumber: parseInt(m[1], 10), startColumn: parseInt(m[2], 10),
          endLineNumber: parseInt(m[3], 10), endColumn: parseInt(m[4], 10)
        });
      }
    }
    return annotations;
  }

  static async compileFiles(files: File[], from: Language, to: Language, options = ""): Promise<{ [name: string]: (string | ArrayBuffer); }> {
    gaEvent("compile", "Service", `${from}->${to}`);

    const service = await createCompilerService(from, to);

    const fileNameMap: { [name: string]: File } = files.reduce((acc: any, f: File) => {
      acc[getProjectFilePath(f)] = f;
      return acc;
    }, {} as any);

    const input = {
      files: files.reduce((acc: any, f: File) => {
        acc[getProjectFilePath(f)] = {
          content: f.getData(),
        };
        return acc;
      }, {} as any),
      options,
    };
    const result = await service.compile(input);

    for (const file of files) {
      file.setProblems([]);
    }

    for (const [name, item] of Object.entries(result.items)) {
      const { fileRef, console } = item;
      if (!fileRef || !console) {
        continue;
      }
      const file = fileNameMap[fileRef];
      if (!file) {
        continue;
      }
      const markers = Service.getMarkers(console);
      if (markers.length > 0) {
        monaco.editor.setModelMarkers(file.buffer, "compiler", markers);
        file.setProblems(markers.map(marker => {
          return Problem.fromMarker(file, marker);
        }));
      }
    }

    if (!result.success) {
      throw new Error(result.console);
    }

    const outputFiles: any = {};
    for (const [name, item] of Object.entries(result.items)) {
      const { content } = item;
      if (content) {
        outputFiles[name] = content;
      }
    }
    return outputFiles;
  }

  static async compileFile(file: File, from: Language, to: Language, options = ""): Promise<any> {
    const result = await Service.compileFileWithBindings(file, from, to, options);
    return result.wasm;
  }

  static async compileFileWithBindings(file: File, from: Language, to: Language, options = ""): Promise<any> {
    if (to !== Language.Wasm) {
      throw new Error(`Only wasm target is supported, but "${to}" was found`);
    }
    const result = await Service.compileFiles([file], from, to, options);
    const expectedOutputFilename = "a.wasm";
    let output: any = {
      wasm: result[expectedOutputFilename],
    };
    const expectedWasmBindgenJsFilename = "wasm_bindgen.js";
    if (result[expectedWasmBindgenJsFilename]) {
      output = {
        ...output,
        wasmBindgenJs: result[expectedWasmBindgenJsFilename],
      };
    }
    return output;
  }

  static async disassembleWasm(buffer: ArrayBuffer, status: IStatusProvider): Promise<string> {
    gaEvent("disassemble", "Service", "wabt");
    status && status.push("Disassembling with Wabt");
    const result = await this.worker.disassembleWasmWithWabt(buffer);
    status && status.pop();
    return result;
  }

  static async disassembleWasmWithWabt(file: File, status?: IStatusProvider) {
    const result = await Service.disassembleWasm(file.getData() as ArrayBuffer, status);
    const output = file.parent.newFile(file.name + ".wat", FileType.Wat);
    output.description = "Disassembled from " + file.name + " using Wabt.";
    output.setData(result);
  }

  static async assembleWat(wat: string, status?: IStatusProvider): Promise<ArrayBuffer> {
    gaEvent("assemble", "Service", "wabt");
    status && status.push("Assembling Wat with Wabt");
    let result = null;
    try {
      result = await this.worker.assembleWatWithWabt(wat);
    } catch (e) {
      throw e;
    } finally {
      status && status.pop();
    }
    return result;
  }

  static async assembleWatWithWabt(file: File, status?: IStatusProvider) {
    const result = await Service.assembleWat(file.getData() as string, status);
    const output = file.parent.newFile(file.name + ".wasm", FileType.Wasm);
    output.description = "Assembled from " + file.name + " using Wabt.";
    output.setData(result);
  }

  static async createGist(json: any, token: string): Promise<IGithubGistCreated> {
    const url = "https://api.github.com/gists";
    const headers = new Headers({
      "Content-type": "application/json; charset=utf-8"
    })
    headers.append("Authorization", `token ${token}`)
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(json),
      headers: headers
    });
    const gist: IGithubGistCreated = JSON.parse(await response.text());
    console.log("created, now put another description")
    await fetch(url+"/" + gist.id, {
      method: "PATCH",
      body: JSON.stringify({description: json["description"] + `/?g=${gist.id}`}),
      headers: headers
    });
    return gist
  }

  static isConfig(o: any): o is IPlaySeedConfig {
    return o && "directories" in o
  }

  static async getSrcFromGithub(repo: string, token: string): Promise<Array<ILoadGithubSourcesItem>> {
    let name = "config.yml"
    let path = `.play-seed/${name}`
    let configUrl = `https://api.github.com/repos/${repo}/contents/${path}`
    let configSrc = await this.getBlobFromGithub(configUrl, token)
    if (configSrc) {
      let configFile = this.fileFromContent(configSrc, name, path)
      let configData = configFile.data
      let config = yaml.safeLoad(configData)
      if (config instanceof Object && this.isConfig(config)) {
        if (config.directories) {
          let loadingPromises = config.directories.map(dir => this.getFolderFromGithub(repo, token, dir))
          loadingPromises.push(this.getFolderFromGithub(repo, token, ".play-seed"))
          let results = await Promise.all(loadingPromises)
          return results.flatMap(it => it)
        } else {
          console.log("do not see directories in config: ", config)
        }
      } else {
        console.log("config is wrong: ", config)

      }
    }

    // default folders
    return (await Promise.all([
      this.getFolderFromGithub(repo, token, "src"), 
      this.getFolderFromGithub(repo, token, ".play-seed")]))
    .flatMap(it => it)
  }

  static async getFolderFromGithub(repo: string, token: string, folder: string): Promise<Array<ILoadGithubSourcesItem>> {
    const url = `https://api.github.com/repos/${repo}/contents/${folder}`
    const headers = new Headers({
      "Content-type": "application/json; charset=utf-8"
    })
    if (token) {
      headers.append("Authorization", `token ${token}`)
    }
    const response = await fetch(url, {
      headers: headers
    });
    if (response.status >= 200 && response.status < 300) {
      return await response.json()
    } else {
      if (response.statusText == "rate limit exceeded") {
        this.initalizeGithubLogin(this.searchSubstring())
      }
      console.log("Failed to get GitHub response: " + response.statusText)
      return []
    }
  }

  static async getReadmeFromGithub(repo: string, token: string): Promise<ILoadGithubFileContent | undefined> {
    const url = `https://api.github.com/repos/${repo}/contents/README.md`
    return await this.getBlobFromGithub(url, token)
  }

  static async getBlobFromGithub(url: string, token: string): Promise<ILoadGithubFileContent | undefined> {
    const headers = new Headers({
      "Content-type": "application/json; charset=utf-8"
    })
    if (token) {
      headers.append("Authorization", `token ${token}`)
    }
    const response = await fetch(url, {
      headers: headers
    });
    if (response.status >= 200 && response.status < 300) {
      return await response.json()
    } else {
      return undefined
    }
  }

  static async getSrcFileFromGithub(url: string, token: string): Promise<ILoadGithubFileContent> {
    const headers = new Headers({
      "Content-type": "application/json; charset=utf-8"
    })
    if (token) {
      headers.append("Authorization", `token ${token}`)
    }
    const response = await fetch(url, {
      headers: headers
    });
    if (response.status >= 200 && response.status < 300) {
      return await response.json()
    } else {
      throw Error(await response.json())
    }
  }

  static fileFromContent(content: ILoadGithubFileContent, name: string, path: string) {
    try { 
      const enc = content.encoding
      if (enc != "base64") {
        throw Error("Unknown encoding in github response: ${enc}")
      }
      const data = content.content.replace(/\n/g, "")
      const unbase = Base64.toByteArray(data)
      const decoded = new TextDecoder("utf-8").decode(unbase)
      return {
        name: name,
        data: decoded,
        path: path,
      }
    } catch (e) {
      console.error("Problem with data decoding: ", e)
      throw e
    }
  }

  static oauthState(length: number) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var array = new Uint32Array(length)
    window.crypto.getRandomValues(array)
    var charactersLength = characters.length;
    const max: number = (2 ** 32) - 1
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor((array[i] / max) * charactersLength));
    }
    return result;
  }

  static async initalizeGithubLogin(ret: string, anotherWindow: boolean = false) {
    const config = await getConfig()
    if (anotherWindow) {
      ret = ret + "&closeAfterLogin"
    }
    const redirect = encodeURIComponent(`${config.ghAuthRedirect}?auth-gh&${ret}`)
    const scopes = encodeURIComponent("user:email gist")
    const state = this.oauthState(64)
    const clientId = config.ghCliendId
    sessionStorage.setItem('githubOauthState', state)
    const url = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirect}&scope=${scopes}&state=${state}`
    if (!anotherWindow) {
      window.location.replace(url)
    } else {
      window.open(url)
    }
  }

  static async finishGithubLogin(code: string, onSuccess: () => void, onFail: () => void,) {
    const state = sessionStorage.getItem('githubOauthState')
    const savedState = sessionStorage.getItem('githubOauthState')
    if (savedState != state) {
      throw new Error("Login failed, states are different")
    }
    const config = await getConfig()
    const url = config.ghAccessToken
    const gh_access_req = {
      code: code,
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({ "Content-type": "application/json; charset=utf-8" }),
      body: JSON.stringify(gh_access_req),
    })
    if (response.status >= 200 && response.status < 300) {
      const result: IGithubAccessToken = await response.json()
      localStorage.setItem('githubOauthToken', result.access_token)
      onSuccess()
    }
  }

  static async getTreeFromGithub(url: string, token: string, base: string): Promise<Array<IGithubFile>> {
    const headers = new Headers({
      "Content-type": "application/json; charset=utf-8"
    })
    if (token) {
      headers.append("Authorization", `token ${token}`)
    }
    const response = await fetch(url, {
      headers: headers
    });
    if (response.status >= 200 && response.status < 300) {
      const tree: ILoadGithubTree = await response.json()

      const loadedTreeLayer = await Promise.all(tree.tree.map(async (item) => {
        if (item.type == "blob") {
          let blob = await this.getBlobFromGithub(item.url, token)
          return [this.fileFromContent(blob, item.path, base + "/" + item.path)]
        }
        if (item.type == "tree") {
          return await this.getTreeFromGithub(item.url, token, base + "/" + item.path)
        }
        return []
      }))
      return loadedTreeLayer.flatMap(it => it)
    } else {
      throw Error(await response.json())
    }
  }
  static async getSourcesFromGist(gist: string): Promise<ILoadGithubSources> {
    return this.withGithubAuth(
      {success: false, error: new Error("")}, 
      "Failed to get gist", 
      (token) => this.getSourcesFromGist_(gist ,token))
  }
  
  static async getSourcesFromGist_(gist: string, token: string): Promise<ILoadGithubSources> {
    let url = `https://api.github.com/gists/${gist}`
    const headers = new Headers({
      "Content-type": "application/json; charset=utf-8"
    })
    if (token) {
      headers.append("Authorization", `token ${token}`)
    }
    const response = await fetch(url, {
      headers: headers
    });
    if (response.status >= 200 && response.status < 300) {
      const data: ILoadGithubGistContent = await response.json()
      if (data.truncated) {
        return {
          success: false,
          error: new Error("Do not support loading of big gists yet")
        }
      }
      const files = Object.entries(data.files).map(([key, value])=>{
        if (value.truncated) {
          throw new Error("Do not support loading of big gists yet")
        }
        const keySplit = key.split("--")
        const path = keySplit.join("/")
        const name = keySplit[keySplit.length - 1]        
        const data = value.content
        const file: IGithubFile = {
          data,
          name,
          path, 
        }
        return file
      })
      return {
        success: true,
        files,
      }
    } else {
      throw Error(await response.json())
    }

  }

  static async getSourcesFromGithub(repo: string): Promise<ILoadGithubSources> {
    const token = localStorage.getItem('githubOauthToken')
    try {
      const sources = await this.getSrcFromGithub(repo, token)
      const files: IGithubFile[] = (await Promise.all(sources.flatMap(async (srcFile) => {
        if (srcFile.type == 'submodule') {
          return [] // TODO: support submodules too
        }

        if (srcFile.type == 'dir') {
          return await this.getTreeFromGithub(srcFile.git_url, token, srcFile.path)
        }

        if (!srcFile.name.endsWith(".rs") && !srcFile.name.endsWith(".css") && !srcFile.name.endsWith(".js") && !srcFile.name.endsWith(".html")) {
          return []
        }
        const srcFileResponse = await this.getSrcFileFromGithub(srcFile.git_url, token)
        return [this.fileFromContent(srcFileResponse, srcFile.name, srcFile.path)]
      }))).flatMap(it => it)

      const readme = await this.getReadmeFromGithub(repo, token)
      if (readme) {
        const file = this.fileFromContent(readme, "README.md", "README.md")
        files.push(file)
      }
      return {
        files: files,
        success: true,
      }
    } catch (e) {
      if (e instanceof Error) {
        return {
          error: e,
          success: false,
        }
      }
      throw e
    }
  }

  static async loadGithubFilesIntoProject(files: IGithubFile[], project: Project): Promise<any> {
    for (const f of files) {
      const type = fileTypeFromFileName(f.path)
      const file = project.newFile(f.path, type, false)
      file.setData(f.data)
    }
  }

  static async loadJSON(uri: string): Promise<ILoadFiddleResponse> {
    const url = "https://webassembly-studio-fiddles.herokuapp.com/fiddle/" + uri;
    const response = await fetch(url, {
      headers: new Headers({ "Content-type": "application/json; charset=utf-8" })
    });
    return await response.json();
  }

  static async saveJSON(json: ICreateFiddleRequest, uri: string): Promise<string> {
    const update = !!uri;
    if (update) {
      throw new Error("NYI");
    } else {
      const response = await fetch("https://webassembly-studio-fiddles.herokuapp.com/set-fiddle", {
        method: "POST",
        headers: new Headers({ "Content-type": "application/json; charset=utf-8" }),
        body: JSON.stringify(json)
      });
      let jsonURI = (await response.json()).id;
      jsonURI = jsonURI.substring(jsonURI.lastIndexOf("/") + 1);
      return jsonURI;
    }
  }

  static parseFiddleURI(): string {
    let uri = window.location.search.substring(1);
    if (uri) {
      const i = uri.indexOf("/");
      if (i > 0) {
        uri = uri.substring(0, i);
      }
    }
    return uri;
  }

  static async exportToGist(content: File): Promise<IGithubGistCreated> {
    gaEvent("export", "Service", "gist");
    const files: any = {};
    function serialize(file: File) {
      if (file instanceof Directory) {
        file.mapEachFile((file: File) => serialize(file), true);
      } else {
        const path = file.getPath(file.getProject())
        if (path.includes("--")) {
          throw new Error(`Cannot create gist for a file ${path}: path should not contain double dash '--'`)
        }
        files[path.replace(/\//g,"--")] = { content: file.data };
      }
    }
    serialize(content);
    const json: any = { description: "source: https://ide.play-seed.dev", public: true, files };
    try {
      return await this.createGistR(json)
    } catch (e) {
      console.log("Failed to create gist: " + JSON.stringify(e))
    }
  }

  static searchSubstring() {
    const search = window.location.search
    if (search && search.length > 0) {
      return search.substring(1)
    }
    return search
  }

  static withGithubAuth<T>(failResolve: T, failMessage: string, f: (token: string)=> Promise<T>, retryAttempt: number = 0): Promise<T>{
    return new Promise(async (resolve, reject) => {
      const token = localStorage.getItem('githubOauthToken')
      if (token) {
        resolve(await f(token))
        return
      }
      if (retryAttempt == 0) {
        this.initalizeGithubLogin(this.searchSubstring(), true)
      }
      if (retryAttempt < 20) {
        setTimeout(async () => {
          resolve(await this.withGithubAuth(failResolve, failMessage, f, retryAttempt + 1))
        }, 3000)
      } else {
        console.error(failMessage)
        resolve(failResolve)
      }
    })

  }

  static createGistR(json: any): Promise<IGithubGistCreated> {
    return this.withGithubAuth({ html_url:"", id: ""}, 
      "Cannot create Gist: too much attempts to login to Github", 
      (token) => this.createGist(json, token))
  }

  static async saveProject(project: Project, openedFiles: string[][], uri?: string): Promise<string> {
    const files: IFiddleFile[] = [];
    project.forEachFile((f: File) => {
      let data: string;
      let type: "binary" | "text";
      if (isBinaryFileType(f.type)) {
        data = base64EncodeBytes(new Uint8Array(f.data as ArrayBuffer));
        type = "binary";
      } else {
        data = f.data as string;
        type = "text";
      }
      const file = {
        name: f.getPath(project),
        data,
        type
      };
      files.push(file);
    }, true, true);
    return await this.saveJSON({
      files
    }, uri);
  }

  static loadFilesIntoProject(files: IFiddleFile[], project: Project, base: URL = null): Promise<any> {
    return Promise.all(files.map(async(f)=>{
      const type = fileTypeFromFileName(f.name);
      const file = project.newFile(f.name, type, false);
      let data: string | ArrayBuffer;
      if (f.data) {
        if (f.type === "binary") {
          data = decodeRestrictedBase64ToBytes(f.data).buffer as ArrayBuffer;
        } else {
          data = f.data;
        }
      } else {
        const request = await fetch(new URL(f.name, base).toString());
        if (f.type === "binary") {
          data = await request.arrayBuffer();
        } else {
          data = await request.text();
        }
      }
      file.setData(data);
    }))    
  }

  static lazyLoad(uri: string, status?: IStatusProvider): Promise<any> {
    return new Promise((resolve, reject) => {
      status && status.push("Loading " + uri);
      const self = this;
      const d = window.document;
      const b = d.body;
      const e = d.createElement("script");
      e.async = true;
      e.src = uri;
      b.appendChild(e);
      e.onload = function () {
        status && status.pop();
        resolve(this);
      };
      // TODO: What about fail?
    });
  }

  static async optimizeWasmWithBinaryen(file: File, status?: IStatusProvider) {
    assert(this.worker);
    gaEvent("optimize", "Service", "binaryen");
    let data = file.getData() as ArrayBuffer;
    status && status.push("Optimizing with Binaryen");
    data = await this.worker.optimizeWasmWithBinaryen(data);
    status && status.pop();
    file.setData(data);
    file.buffer.setValue(await Service.disassembleWasm(data, status));
  }

  static async validateWasmWithBinaryen(file: File, status?: IStatusProvider): Promise<boolean> {
    gaEvent("validate", "Service", "binaryen");
    const data = file.getData() as ArrayBuffer;
    status && status.push("Validating with Binaryen");
    const result = await this.worker.validateWasmWithBinaryen(data);
    status && status.pop();
    return !!result;
  }

  static async getWasmCallGraphWithBinaryen(file: File, status?: IStatusProvider) {
    gaEvent("call-graph", "Service", "binaryen");
    const data = file.getData() as ArrayBuffer;
    status && status.push("Creating Call Graph with Binaryen");
    const result = await this.worker.createWasmCallGraphWithBinaryen(data);
    status && status.pop();
    const output = file.parent.newFile(file.name + ".dot", FileType.DOT);
    output.description = "Call graph created from " + file.name + " using Binaryen's print-call-graph pass.";
    output.setData(result);
  }

  static async disassembleWasmWithBinaryen(file: File, status?: IStatusProvider) {
    gaEvent("disassemble", "Service", "binaryen");
    const data = file.getData() as ArrayBuffer;
    status && status.push("Disassembling with Binaryen");
    const result = await this.worker.disassembleWasmWithBinaryen(data);
    status && status.pop();
    const output = file.parent.newFile(file.name + ".wat", FileType.Wat);
    output.description = "Disassembled from " + file.name + " using Binaryen.";
    output.setData(result);
  }

  static async convertWasmToAsmWithBinaryen(file: File, status?: IStatusProvider) {
    gaEvent("asm.js", "Service", "binaryen");
    const data = file.getData() as ArrayBuffer;
    status && status.push("Converting to asm.js with Binaryen");
    const result = await this.worker.convertWasmToAsmWithBinaryen(data);
    status && status.pop();
    const output = file.parent.newFile(file.name + ".asm.js", FileType.JavaScript);
    output.description = "Converted from " + file.name + " using Binaryen.";
    output.setData(result);
  }

  static async assembleWatWithBinaryen(file: File, status?: IStatusProvider) {
    gaEvent("assemble", "Service", "binaryen");
    const data = file.getData() as string;
    status && status.push("Assembling with Binaryen");
    const result = await this.worker.assembleWatWithBinaryen(data);
    status && status.pop();
    const output = file.parent.newFile(file.name + ".wasm", FileType.Wasm);
    output.description = "Converted from " + file.name + " using Binaryen.";
    output.setData(result);
  }

  static downloadLink: HTMLAnchorElement = null;
  static download(file: File) {
    if (!Service.downloadLink) {
      Service.downloadLink = document.createElement("a");
      Service.downloadLink.style.display = "none";
      document.body.appendChild(Service.downloadLink);
    }
    const url = URL.createObjectURL(new Blob([file.getData()], { type: "application/octet-stream" }));
    Service.downloadLink.href = url;
    Service.downloadLink.download = file.name;
    if (Service.downloadLink.href as any !== document.location) {
      Service.downloadLink.click();
    }
  }

  static clangFormatModule: any = null;
  // Kudos to https://github.com/tbfleming/cib
  static async clangFormat(file: File, status?: IStatusProvider) {
    gaEvent("format", "Service", "clang-format");
    function format() {
      const result = Service.clangFormatModule.ccall("formatCode", "string", ["string"], [file.buffer.getValue()]);
      file.buffer.setValue(result);
    }

    if (Service.clangFormatModule) {
      format();
    } else {
      await Service.lazyLoad("lib/clang-format.js", status);
      const response = await fetch("lib/clang-format.wasm");
      const wasmBinary = await response.arrayBuffer();
      const module: any = {
        postRun() {
          format();
        },
        wasmBinary
      };
      Service.clangFormatModule = Module(module);
    }
  }

  static async disassembleX86(file: File, status?: IStatusProvider, options = "") {
    gaEvent("disassemble", "Service", "capstone.x86");
    if (typeof capstone === "undefined") {
      await Service.lazyLoad("lib/capstone.x86.min.js", status);
    }
    const output = file.parent.newFile(file.name + ".x86", FileType.x86);

    function toBytes(a: any) {
      return a.map(function (x: any) { return padLeft(Number(x).toString(16), 2, "0"); }).join(" ");
    }

    const service = await createCompilerService(Language.Wasm, Language.x86);
    const input = {
      files: {
        "in.wasm": {
          content: file.getData(),
        },
      },
      options,
    };
    const result = await service.compile(input);
    const json: any = result.items["a.json"].content;
    let s = "";
    const cs = new capstone.Cs(capstone.ARCH_X86, capstone.MODE_64);
    const annotations: any[] = [];
    const assemblyInstructionsByAddress = Object.create(null);
    for (let i = 0; i < json.regions.length; i++) {
      const region = json.regions[i];
      s += region.name + ":\n";
      const csBuffer = decodeRestrictedBase64ToBytes(region.bytes);
      const instructions = cs.disasm(csBuffer, region.entry);
      const basicBlocks: any = {};
      instructions.forEach(function (instr: any, i: any) {
        assemblyInstructionsByAddress[instr.address] = instr;
        if (isBranch(instr)) {
          const targetAddress = parseInt(instr.op_str, 10);
          if (!basicBlocks[targetAddress]) {
            basicBlocks[targetAddress] = [];
          }
          basicBlocks[targetAddress].push(instr.address);
          if (i + 1 < instructions.length) {
            basicBlocks[instructions[i + 1].address] = [];
          }
        }
      });
      instructions.forEach(function (instr: any) {
        if (basicBlocks[instr.address]) {
          s += " " + padRight(toAddress(instr.address) + ":", 39, " ");
          if (basicBlocks[instr.address].length > 0) {
            s += "; " + toAddress(instr.address) + " from: [" + basicBlocks[instr.address].map(toAddress).join(", ") + "]";
          }
          s += "\n";
        }
        s += "  " + padRight(instr.mnemonic + " " + instr.op_str, 38, " ");
        s += "; " + toAddress(instr.address) + " " + toBytes(instr.bytes) + "\n";
      });
      s += "\n";
    }
    output.setData(s);
  }

  private static binaryExplorerMessageListener: (e: any) => void;

  static openBinaryExplorer(file: File) {
    window.open(
      "//wasdk.github.io/wasmcodeexplorer/?api=postmessage",
      "",
      "toolbar=no,ocation=no,directories=no,status=no,menubar=no,location=no,scrollbars=yes,resizable=yes,width=1024,height=568"
    );
    if (Service.binaryExplorerMessageListener) {
      window.removeEventListener("message", Service.binaryExplorerMessageListener, false);
    }
    Service.binaryExplorerMessageListener = (e: any) => {
      if (e.data.type === "wasmexplorer-ready") {
        window.removeEventListener("message", Service.binaryExplorerMessageListener, false);
        Service.binaryExplorerMessageListener = null;
        const dataToSend = new Uint8Array((file.data as any).slice(0));
        e.source.postMessage({
          type: "wasmexplorer-load",
          data: dataToSend
        }, "*", [dataToSend.buffer]);
      }
    };
    window.addEventListener("message", Service.binaryExplorerMessageListener, false);
  }

  static async import(path: string): Promise<any> {
    const { project, global } = getCurrentRunnerInfo();
    const context = new RewriteSourcesContext(project);
    context.logLn = console.log;
    context.createFile = (src: ArrayBuffer | string, type: string) => {
      const blob = new global.Blob([src], { type, });
      return global.URL.createObjectURL(blob);
    };

    const url = processJSFile(context, path);
    // Create script tag to load ES module.
    const script = global.document.createElement("script");
    script.setAttribute("type", "module");
    script.setAttribute("async", "async");
    const id = `__import__${Math.random().toString(36).substr(2)}`;
    const scriptReady = new Promise((resolve, reject) => {
      global[id] = resolve;
    });
    script.textContent = `import * as i from '${url}'; ${id}(i);`;
    global.document.head.appendChild(script);
    const module = await scriptReady;
    // Module loaded -- cleaning up
    script.remove();
    delete global[id];
    return module;
  }

  static async compileMarkdownToHtml(src: string): Promise<string> {
    if (typeof showdown === "undefined") {
      await Service.lazyLoad("lib/showdown.min.js");
    }
    const converter = new showdown.Converter({ tables: true, ghCodeBlocks: true });
    showdown.setFlavor("github");
    return converter.makeHtml(src);
  }

  static async twiggyWasm(file: File, status: IStatusProvider): Promise<string> {
    const buffer = file.getData() as ArrayBuffer;
    gaEvent("disassemble", "Service", "twiggy");
    status && status.push("Analyze with Twiggy");
    const result = await this.worker.twiggyWasm(buffer);
    const output = file.parent.newFile(file.name + ".md", FileType.Markdown);
    output.description = "Analyzed " + file.name + " using Twiggy.";
    output.setData(result);
    status && status.pop();
    return result;
  }
}
